<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { required, } from "vuelidate/lib/validators";

import Choices from "choices.js";


import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'

import { useSystemApi } from "@/apis/system";
import { useTaskApi } from "@/apis/task";

/**
 * Advanced table component
 */
export default {
  page: {
    title: '生产加工',
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    VueBootstrapTypeahead

  },

  validations() {
    return {
      t_o_count: { required },
    }
  },

  data() {
    return {
      tableData: [],
      model_data: [],
      deptList: [],
      t_dept_list: [],

      title: this.$t('produce.list_page.sub_title'),
      items: [
        {
          text: this.$t('produce.list_page.nav_first_item'),
          href: "/",
        },
        {
          text: this.$t('produce.list_page.nav_second_item'),
          active: true,
        },
      ],
      issue_url: '',
      totalRows: 1,
      currentPage: 1,
      perPage: 30,


      fields: [
        {
          key: "Model",
          label: '型号',
          sortable: true,
        },
        {
          key: "ModelName",
          label: '型号名称',
          sortable: true,
        },
        {
          key: "ModelNumber",
          label: '批次',
          sortable: true,
        },
        {
          key: "PCount",
          label: '投产数量',
          sortable: true,
        },
        {
          key: "ICount",
          label: '流入数量',
          sortable: true,
        },
        {
          key: "OCount",
          label: '流出数量',
          sortable: true,
        },


        {
          key: "TaskNumber",
          label: '任务编号',
          sortable: true,
        },

        {
          key: "Dept",
          label: '部门',
          sortable: true,
        },


        {
          key: "Pos",
          label: '操作',
          sortable: false,
        },
      ],


      p_model: '',
      p_dept: '',

      t_dept: '',
      t_remarks: '',
      t_o_count: 0,
      current_produce: {},
      c_dept: '',
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },


  },
  mounted() {

    this.query_dept()
    this.query_produce_list()
  },
  methods: {
    query_produce_list() {
      let data = {
        page: {
          page_no: this.currentPage,
          page_size: this.perPage
        }
      }

      if (this.p_dept) {
        data.dept = this.p_dept
      }

      if (this.p_model) {
        data.model = this.p_model
      }
      this.tableData = []
      useTaskApi().list_task_produce_list(data).then(res => {
        if (res.err == 0) {
          this.tableData = res.data
          this.totalRows = res.page.total_count
        }
      })
    },


    query_dept() {
      useSystemApi().list_system_conf('DEPT').then(res => {
        if (res.err == 0) {
          this.deptList = [
            {
              value: '',
              label: '全部',
              selected: true
            }
          ]

          res.data.map(e => {

            this.deptList.push({
              code: e.key_field,
              name: e.value_field,
              remarks: e.remarks,
              id: e.id,
              value: e.key_field,
              label: e.value_field
            })

            this.t_dept_list.push({
              code: e.key_field,
              name: e.value_field,
              remarks: e.remarks,
              id: e.id,
              value: e.key_field,
              label: e.value_field,
            })
          })
        }

        new Choices('#dept_list', { choices: this.deptList })

        this.t_dept_list[0].selected = true
        this.t_dept = this.t_dept_list[0].value
        new Choices('#t_dept_list', { choices: this.t_dept_list })
        new Choices('#stock_dept_list', { choices: this.t_dept_list })
        

      })
    },


    search_model(searched_str) {
      useSystemApi().model_search('MODEL', searched_str).then(res => {
        if (res.err == 0) {
          this.model_data = []
          res.data.map(e => {
            this.model_data.push(e.key_field)
          })
        }

      })
    },



    pageChange(p) {
      this.currentPage = p
      this.query_stock_list()
    },

    showTransferDialog(p) {
      this.current_produce = p
      this.t_o_count = p.i_count
      this.c_dept = p.dept_name
      this.$bvModal.show('transfer_dialog')
    },

    showStockRequestDialog(p) {
      this.current_produce = p
      this.t_o_count = p.i_count
      this.c_dept = p.dept_name
      this.$bvModal.show('stock_request_dialog')
    },



    transfer_produce() {

      let transfer_p = Object.assign({}, this.current_produce)
      transfer_p.o_count = this.t_o_count
      let data = {
        action  : 'TRANSFER',
        dept    : this.t_dept,
        produces: [transfer_p]
      }
      useTaskApi().produce_transfer(data).then(res => {

        if (res.err == 0) {
          this.query_produce_list();
          this.$alertify.message('流转成功');
          this.$bvModal.hide('transfer_dialog')
        } else {
          this.$alertify.error("流转失败 " + res.err + ":" + res.msg);
        }
      })
    },


    stock_request_produce() {
      let transfer_p = Object.assign({}, this.current_produce)
      transfer_p.o_count = this.t_o_count
      let data = {
        action        : 'STOCK_REQ',
        dept          : this.t_dept,
        produces      : [transfer_p],
        stock_req     : '1'
      }
      useTaskApi().produce_transfer(data).then(res => {

        if (res.err == 0) {
          this.query_produce_list();
          this.$alertify.message('提交入库成功');
          this.$bvModal.hide('stock_request_dialog')
        } else {
          this.$alertify.error("提交入库失败 " + res.err + ":" + res.msg);
        }
      })
    }



  },

  watch: {
    p_model: function (new_val) {
      this.search_model(new_val)
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">


        <b-modal id="transfer_dialog" centered title="通知流转" title-class="text-black font-18" body-class="p-3" size="lg"
          hide-footer hide-header-close no-close-on-backdrop no-close-on-esc :static="true">
          <div class="row mb-3">

            <div class="col-md-3">
              <label class="form-label">型号</label>
              <input type="text" class="form-control" v-model="current_produce.model_code" readonly>
            </div>

            <div class="col-md-2">
              <label class="form-label">批号</label>
              <input type="text" class="form-control" v-model="current_produce.model_number" readonly/>
            </div>

            <div class="col-md-2">
              <label class="form-label">当前部门</label>
              <input type="text" class="form-control" v-model="c_dept" readonly>
            </div>

            <div class="col-md-3">
              <label class="form-label">流转部门</label>
              <select class="form-control" id="t_dept_list" v-model="t_dept">
              </select>
            </div>

            <div class="col-md-2">
              <label class="form-label">流转数量</label>
              <input type="text" class="form-control" v-model="t_o_count" />
            </div>

            
          </div>
          <div class="row mb-3">
            <div class="col-md-12">
              <label class="form-label">流转说明</label>
              <input type="text" class="form-control" v-model="t_remarks" maxlength="100"/>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="d-flex flex-wrap gap-2 justify-content-end d-flex align-items-right">
                <button type="button" class="btn btn-secondary w-sm" @click="$bvModal.hide('transfer_dialog')">取消
                </button>
                <button type="submit" class="btn btn-primary w-sm" @click="transfer_produce"> 确认流转</button>
              </div>
            </div>
          </div>
        </b-modal>


        <b-modal id="stock_request_dialog" centered title="通知入库" title-class="text-black font-18" body-class="p-3"
          size="lg" hide-footer hide-header-close no-close-on-backdrop no-close-on-esc :static="true">
          <div class="row mb-3">

            <div class="col-md-3">
              <label class="form-label">型号</label>
              <input type="text" class="form-control" v-model="current_produce.model_code" readonly>
            </div>
            <div class="col-md-2">
              <label class="form-label">批号</label>
              <input type="text" class="form-control" v-model="current_produce.model_number" readonly/>
            </div>

            <div class="col-md-2">
              <label class="form-label">当前部门</label>
              <input type="text" class="form-control" v-model="c_dept" readonly>
            </div>

            <div class="col-md-3">
              <label class="form-label">库存部门</label>
              <select class="form-control" id="stock_dept_list" v-model="t_dept">
              </select>
            </div>

            <div class="col-md-2">
              <label class="form-label">入库数量</label>
              <input type="text" class="form-control" v-model="t_o_count" />
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-md-6">
              <label class="form-label">入库单说明</label>
              <input type="text" class="form-control" v-model="t_remarks" maxlength="100" />
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="d-flex flex-wrap gap-2 justify-content-end d-flex align-items-right">
                <button type="button" class="btn btn-secondary w-sm" @click="$bvModal.hide('stock_request_dialog')">取消
                </button>
                <button type="submit" class="btn btn-primary w-sm" @click="stock_request_produce"> 提交入库</button>
              </div>
            </div>
          </div>
        </b-modal>

        <div class="card">
          <div class="card-body">

            <div class="row">
              <div class="col-sm-6 col-md-3">

                <div class="row">
                  <div class="col-md-2">
                    <label class="d-inline-flex align-items-center"> 型号选择&nbsp;</label>
                  </div>
                  <div class="col-md-6">
                    <vue-bootstrap-typeahead v-model="p_model" :data="model_data" autocomplete="off" :inputClass="{}" />
                  </div>

                </div>


              </div>

              <div class="col-sm-12 col-md-3">
                <div class="row">
                  <div class="col-md-2">
                    <label class="d-inline-flex align-items-center"> 部门选择&nbsp;</label>
                  </div>
                  <div class="col-md-6">
                    <select class="form-control" id="dept_list" v-model="p_dept">
                    </select>
                  </div>

                </div>


              </div>

              <div class="col-sm-6 col-md-3">
              </div>
              <!-- Search -->
              <div class="col-sm-6 col-md-3">
                <div class=" d-flex flex-wrap  justify-content-md-end gap-2">
                  <b-button variant="secondary" @click="query_produce_list">查询</b-button>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Row -->


            <div class="table-responsive mb-0">
              <b-table class="datatables" :items="tableData" :fields="fields" responsive="sm" :per-page="perPage">

                <template #cell(Model)="data">
                  {{ data.item.model_code }}
                </template>
                <template #cell(ModelName)="data">
                  {{ data.item.model_name }}
                </template>
                <template #cell(ModelNumber)="data">
                  {{ data.item.model_number }}
                </template>
                <template #cell(PCount)="data">
                  {{ data.item.p_count }}
                </template>
                <template #cell(ICount)="data">
                  {{ data.item.i_count }}
                </template>
                <template #cell(OCount)="data">
                  {{ data.item.o_count }}
                </template>
                <template #cell(TaskNumber)="data">
                  {{ data.item.task_number }}
                </template>
                <template #cell(Dept)="data">
                  {{ data.item.dept_name }}
                </template>

                <template #cell(Pos)="data">
                  <b-dropdown variant="white" toggle-class="p-0">
                    <template v-slot:button-content>
                      <i class="mdi mdi-dots-horizontal font-size-18 text-muted"></i>
                    </template>
                    <li>
                      <b-dropdown-item @click="showTransferDialog(data.item)"><i
                          class="mdi mdi-pencil font-size-16 text-success me-1"></i>
                        通知流转 </b-dropdown-item>
                        <b-dropdown-item @click="showStockRequestDialog(data.item)"><i
                          class="mdi mdi-pencil font-size-16 text-success me-1"></i>
                        通知入库 </b-dropdown-item>
                    </li>


                  </b-dropdown>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>
